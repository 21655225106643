<template>
  <ContentWrapper>
    <div class="d-flex justify-content-between">
      <el-button
          @click="$router.push({name: 'VacationRequestCreate'})"
          size="small"
          type="primary"
      >
        <font-awesome-icon
            class="mr-1"
            icon="plus"
        />
        {{ $t('system.vacation_request') }}
      </el-button>
      <DropdownConfigurator
          :filters="filters"
          :searchString.sync="searchPhrase"
          :showFiltersConfigurations="false"
          @clearFilters="clearFilters"
          @submitSearch="setFiltersAndFetchData"
          disableColumns
      />
    </div>
    <el-divider />
    <DataTable
        :headers="headers"
        :idPath="tableIdentifierColumn"
        :loading="loading"
        :model="data"
        :params.sync="params"
        @clickRow="onClickRow"
        @updateData="fetchData"
    >
      <template v-slot:item.status="{row}">
        <div
            class="m-2"
            style="white-space: normal"
        >
          <el-tag
              :type="getStatusType(row.status)"
              effect="dark"
              size="small"
          >
            {{ $t(`system.${row.status}`) }}
          </el-tag>
        </div>
      </template>
    </DataTable>
    <router-view />
  </ContentWrapper>
</template>

<script>
import axios from 'axios';
import ContentWrapper from '../../components/HOCs/ContentWrapper.vue';
// import DebounceInput from '../../components/DebounceInput.vue';
import DropdownConfigurator from '../../components/DropdownConfigurator/crmDropdownConfigurator.vue';
import DataTable from '../../components/Table/DataTable.vue';
import notification from '../../notification/notify';

export default {
  name: 'VacationRequestsList',
  components: {
    DataTable,
    DropdownConfigurator,
    ContentWrapper,
    // DebounceInput
  },
  data() {
    return {
      headers: [
        {
          key: 'username',
          label: 'system.username',
        },
        {
          key: 'from',
          label: 'system.start_at',
        },
        {
          key: 'to',
          label: 'system.end_at',
        },
        {
          key: 'reason',
          label: 'system.reason',
        },
        {
          key: 'status',
          label: 'system.status',
        },
      ],
      tableIdentifierColumn: 'id',
      data: [
        // {
        //   id: 1,
        //   username: 'Adam',
        //   start_at: '2012-30-12'
        // }
      ],
      filters: [
        {
          checked: false,
          label: 'system.created_at',
          name: 'created_at',
          type: 'dateRangePicker',
          value: null,
        },
        {
          checked: false,
          label: 'system.start_at',
          type: 'dateRangePicker',
          name: 'start_at',
          value: null,
        },
        {
          checked: false,
          label: 'system.end_at',
          type: 'dateRangePicker',
          name: 'end_at',
          value: null,
        },
        {
          checked: false,
          label: 'system.status',
          type: 'select',
          name: 'status',
          value: null,
          dictionary: {
            isRemote: false,
          },
          values: [
            {
              id: 'pending',
              name: 'pending',
            },
            {
              id: 'accepted',
              name: 'accepted',
            },
            {
              id: 'rejected',
              name: 'rejected',
            },
          ],
        },
      ],
      searchPhrase: '',
      loading: false,
      params: {
        column: 'id',
        direction: 'desc',
        per_page: 20,
        page: 1,
        total: 0,
        per_page_sizes: [20, 30, 50, 100],
      },
    };
  },
  beforeRouteUpdate(to, from, next) {
    if (from.name === 'VacationRequest') {
      this.fetchData();
    }
    next();
  },
  beforeMount() {
    this.fetchData();
    this.fetchFiltersValues();
  },
  methods: {
    getStatusType(status) {
      switch (status) {
        case 'pending':
          return 'info';
        case 'rejected':
          return 'danger';
        case 'accepted':
          return 'success';
        default:
          return '#909399';
      }
    },
    async fetchData() {
      const params = {...this.params};
      if (this.searchPhrase) {
        params.search = this.searchPhrase;
      }
      if (this.filters.length) {
        const anotherFilters = this.filters.filter(item => item.checked && (
                item.name !== 'userType' &&
                (item.type === 'switch' ||
                    item.type === 'checkbox' ||
                    (Array.isArray(item.value) && item.value.length) ||
                    (!Array.isArray(item.value) && item.value)
                )
            ),
        );
        anotherFilters.forEach(item => {
          params[item.name] = item.value;
        });
      }
      try {
        const {data} = await axios.get('workorders/leaves', {params});
        this.data = data.data;
        this.params.total = data.meta.total;
      } catch (e) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load'),
            'error');
      }
    },
    fetchFiltersValues() {
      const filters = this.filters.filter(item => item.dictionary && !item.dictionary.isRemote && item.dictionary.sourceUrl);

      filters.forEach(async filter => {
        const idx = this.filters.findIndex(item => item.name === filter.name);
        if (idx >= 0) {
          const {data} = await axios.get(filter.dictionary.sourceUrl);
          this.filters[idx].values = data;
        }
      });
    },
    setFiltersAndFetchData(filters) {
      this.filters = [...filters];
      this.fetchData();
    },
    onClickRow(id) {
      this.$router.push({name: 'VacationRequest', params: {id}});
    },
    clearFilters() {
      this.filters = this.filters.map(item => {
        return {...item, checked: false, value: null};
      });
    },
  },
};
</script>

<style scoped>

</style>
